/** @jsxImportSource theme-ui */
import {
  Button, Flex, IconButton, Input, Label, Spinner, Text, Textarea,
} from 'theme-ui';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ThumbDownIcon from '@fortawesome/fontawesome-free/svgs/solid/thumbs-down.svg';
import ThumbUpIcon from '@fortawesome/fontawesome-free/svgs/solid/thumbs-up.svg';
import Avatar from './Avatar';
import useForm from './useForm';
import Icon from './Icon';

function Claire(props) {
  const {
    formState,
    setFormState,
    isSubmitting,
    setIsSubmitting,
    setValidationMessages,
    validationMessages,
    onChange,
  } = useForm({
    initialState: {
      learningIntention: 'We are learning to ',
      rubricIntention: '',
    },
  });

  const [isSelected, setIsSelected] = useState(null);

  const isDevel = window.location.hostname === 'localhost';

  const rir = !isDevel ? null : `|Level|Success Criteria|Evidence|
  |-----|----------------|--------|
  |1|Needs help to get started.|Does not know the causes of the French Revolution.|
  |2|Can identify one cause of the French Revolution.|For example, can identify the financial crisis as a cause of the French Revolution.|
  |3|Can identify several causes of the French Revolution.|For example, can identify the financial crisis, the decline of the aristocracy, and the rise of Enlightenment ideals as causes of the French Revolution.|
  |4|Can explain how each cause contributed to the French Revolution.|For example, can explain how the financial crisis created an economic disparity between the classes, which in turn led to the decline of the aristocracy and the rise of Enlightenment ideals.|
  |5|Can compare and contrast the causes of the French Revolution to the causes of other revolutions.|For example, can compare and contrast the causes of the French Revolution to the causes of the American Revolution.|
  
  Here are some examples of causes of the French Revolution:
  * Financial crisis: The French government was heavily in debt due to a series of wars, and this led to a financial crisis that affected all levels of society.
  * Decline of the aristocracy: The aristocracy had long held power in France, but it was increasingly seen as corrupt and out of touch. This led to a decline in its power and influence.
  * Rise of Enlightenment ideals: The Enlightenment was an intellectual movement that emphasized reason and individual rights. Its ideas became increasingly popular in France, and they eventually led to the revolution.
  * Growing social inequality: The financial crisis created an economic disparity between the classes, with the poor paying a disproportionate amount of taxes. This created a sense of injustice that eventually led to revolution.
  * Political unrest: The French government was seen as ineffective and out of touch, and this led to widespread political unrest. The people began to demand change, and this ultimately led to revolution.`;

  const lir = !isDevel ? null : `The learning objective __"We are learning to"__ is missing the __verb__ and the __noun__. The verb specifies the action that the learner will be performing and the noun specifies the object of the action. 

Here are some improved versions of the learning objective with examples:

+ We are learning to compare the similarities and differences between two texts.

+ We are learning to analyze the structure of a poem.

+ We are learning to evaluate the credibility of source material.

+ We are learning to calculate the area of a triangle.

+ We are learning to identify the causes of a historical event.

+ We are learning to explain the concept of gravity.`;

  const [learningIntentionResponse, setLearningIntentionResponse] = useState(lir || null);
  const [rubricIntentionResponse, setRubricIntentionResponse] = useState(rir || null);

  const simplePrediction = async () => {
    setIsSubmitting(true);
    setLearningIntentionResponse(null);

    const { learningIntention } = formState;

    fetch('/.netlify/functions/simplePrediction2', {
      method: 'post',
      body: learningIntention,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      response.json().then((result) => {
        if (result.ok) {
          setIsSubmitting(false);
          setLearningIntentionResponse(result.message);
        }
      });
    });
  };

  const generateRubric = () => {
    setIsSubmitting(true);
    setRubricIntentionResponse(null);

    const { rubricIntention } = formState;

    fetch('/.netlify/functions/rubricPrediction', {
      method: 'post',
      body: rubricIntention,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      response.json().then((result) => {
        if (result.ok) {
          setIsSubmitting(false);
          setRubricIntentionResponse(result.message);
        }
      });
    });
  };

  return (
    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
      <Flex sx={{ flexDirection: 'row', gap: 3, alignItems: 'center' }}>
        <Avatar src="https://media.versoapp.com/Images/default-avatar.svg" />
        <Text>
          Hi there, I am Claire the Clarify-ed AI helper.
          Can you enter your learning intention into the box below.
        </Text>
      </Flex>
      <Flex sx={{ gap: 3 }}>
        <Input placeholder="Enter your learning intention" name="learningIntention" onChange={onChange} value={formState.learningIntention} sx={{ backgroundColor: 'white' }} />
        <Button onClick={simplePrediction} disabled={formState.learningIntention.length < 10} sx={{ backgroundColor: formState.learningIntention.length < 10 ? 'neutral.1' : 'primaryLight' }}>Submit</Button>
      </Flex>
      {!learningIntentionResponse && isSubmitting && (
      <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Spinner />
        <Text>Let me have a think</Text>
      </Flex>
      )}
      {learningIntentionResponse && (
        <div>
          <ReactMarkdown
            sx={{ mt: 3 }}
            remarkPlugins={[remarkGfm]}
            children={learningIntentionResponse} // eslint-disable-line react/no-children-prop
          />
        </div>
      )}
      {learningIntentionResponse && (
        <Flex sx={{ gap: 3 }}>
          <Input placeholder="Enter your learning intention" name="rubricIntention" onChange={onChange} value={formState.rubricIntention} sx={{ backgroundColor: 'white' }} />
          <Button onClick={generateRubric} sx={{ backgroundColor: 'primaryLight' }}>Create</Button>
        </Flex>
      )}
      {learningIntentionResponse && isSubmitting && (
      <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Spinner />
        <Text>Let me have a think</Text>
      </Flex>
      )}
      {rubricIntentionResponse && (
      <div
        sx={{
          p: 5,
          mt: 3,
          '& tr:nth-child(even)': {
            backgroundColor: 'white',
          },
          '& th': {
            backgroundColor: 'white',
          },
          '& table, th, td': {
            border: '1px solid',
          },
          '& th, td': {
            px: 4,
          },
        }}
      >
        <ReactMarkdown
          sx={{ mt: 3 }}
          remarkPlugins={[remarkGfm]}
          children={rubricIntentionResponse}
          components={{
            // Map `h1` (`# heading`) to use `h2`s.
            // h1: 'h2',
            // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
            // em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
            ul: ({ node, ...props }) => { return <ul {...props} contentEditable="true" onInput={(event) => { console.log('event:::', event); }} />; },
            p: ({ node, ...props }) => { return <p {...props} contentEditable="true" onInput={(event) => { console.log('event:::', event); }} />; },
            td: ({ node, ...props }) => { return <td><span {...props} sx={{ border: 'none', outline: 'none' }} contentEditable="true" onInput={(event) => { console.log('event:::', event); }} /></td>; },
          }}
        />

        <div sx={{ mt: 2, float: 'right' }}>
          <div>
            Claire is always learning and getting better with every response.
          </div>
          <div sx={{ mt: 2, float: 'right' }}>
            Was this helpful?
            <IconButton
              onClick={() => { setIsSelected('yes'); }}
              sx={{
                p: 2, ml: 4, border: '1px solid', borderColor: 'neutral.2', width: 'auto', cursor: 'pointer', backgroundColor: isSelected === 'yes' ? 'primaryLight' : 'transparent', color: isSelected === 'yes' ? 'textWhite' : 'text',
              }}
            >
              <img
                src={ThumbUpIcon}
                sx={{
                  height: 1, width: 1, mr: 2, color: 'textWhite',
                }}
              />
              {' '}
              Yes
            </IconButton>
            <IconButton
              onClick={() => { setIsSelected('no'); }}
              sx={{
                p: 2, ml: 4, border: '1px solid', borderColor: 'neutral.2', width: 'auto', cursor: 'pointer', backgroundColor: isSelected === 'no' ? 'primaryLight' : 'transparent', color: isSelected === 'no' ? 'textWhite' : 'text',
              }}
            >
              <img src={ThumbDownIcon} sx={{ height: 1, width: 1, mr: 2 }} />
              No
            </IconButton>
            {isSelected === 'no' && (
            <div sx={{ mt: 2 }}>
              <Label>What could we do better?</Label>
              <Textarea />
            </div>
            )}
          </div>
        </div>
      </div>
      )}
    </Flex>
  );
}

export default Claire;

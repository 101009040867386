/** @jsxImportSource theme-ui */

import { Box } from 'theme-ui';

function Avatar({ src }) {
  return (
    <Box sx={{
      borderRadius: 'large',
    }}
    >
      <img src={src} alt="Avatar" />
    </Box>
  );
}

export default Avatar;

export default {
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256, 512],
  sizes: {
    0: 0,
    1: 16,
    2: 24,
    3: 32,
    4: 40,
    5: 48,
    6: 56,
    7: 64,
    8: 96,
    9: 128,
    10: 192,
    11: 256,
    12: 360,
    13: 512,
    14: 928,
    contentSmall: 1024,
    content: 1224,
  },
  breakpoints: ['40em', '52em', '64em', '76em'],
  fonts: {
    body: "'PT Sans', sans-serif",
    heading: "'Nunito', sans-serif",
    comic: "'Comicrazy', sans-serif",
    monospace: 'Menlo, monospace',
  },
  fontSizes: [
    '0.75rem',
    '1rem',
    '1.333rem',
    '1.777rem',
    '2.369rem',
    '3.157rem',
    '4.209rem',
    '5.61rem',
  ],
  fontWeights: {
    thin: '200',
    light: '300',
    normal: '400',
    bold: '700',
    extrabold: '800',
  },
  letterSpacings: {
    tighter: '-0.094rem',
    tight: '-0.031rem',
    normal: '0rem',
    wide: '0.016rem',
    wider: '0.031rem',
    loose: '0.078rem',
    stretched: '0.125rem',
  },
  lineHeights: {
    none: '1rem',
    tight: '1.25rem',
    normal: '1.5rem',
  },
  borderWidths: {
    thin: 1,
    regular: 2,
    thick: 4,
    xThick: 8,
  },
  shadows: [
    '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20)',
    '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
    '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)',
    '0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.20)',
    '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20)',
    '0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20)',
    '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.20)',
    '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20)',
  ],
  radii: {
    small: 4,
    medium: 8,
    large: 16,
    xLarge: 32,
  },
  easing: {
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    slowToQuick: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    quickToSlow: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
    slowToQuickToSlow: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  zIndices: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 999999],
  textStyles: {
    heading1: {
      fontSize: 7,
      fontFamily: 'heading',
      fontWeight: 'thin',
      lineHeight: '7rem',
      letterSpacing: 'tighter',
    },
    heading2: {
      fontSize: 6,
      fontFamily: 'heading',
      fontWeight: 'thin',
      lineHeight: '6rem',
      letterSpacing: 'tight',
    },
    heading3: {
      fontSize: 5,
      fontFamily: 'heading',
      fontWeight: 'thin',
      lineHeight: '4rem',
      letterSpacing: 'normal',
    },
    heading4: {
      fontSize: 4,
      fontFamily: 'heading',
      fontWeight: 'light',
      lineHeight: '3.5rem',
      letterSpacing: 'wide',
    },
    heading5: {
      fontSize: 3,
      fontFamily: 'heading',
      fontWeight: 'normal',
      lineHeight: '2.5rem',
      letterSpacing: 'normal',
    },
    heading6: {
      fontSize: 2,
      fontFamily: 'heading',
      fontWeight: 'normal',
      lineHeight: '2rem',
      letterSpacing: 'wide',
    },
    body: {
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 'normal',
      lineHeight: 'tight',
      letterSpacing: 'wider',
    },
    subtitle: {
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 'bold',
      lineHeight: 'tight',
      letterSpacing: 'loose',
    },
    overline: {
      fontFamily: 'body',
      fontSize: 0,
      fontWeight: 'normal',
      lineHeight: 'none',
      letterSpacing: 'stretched',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'body',
      fontSize: 0,
      fontWeight: 'normal',
      lineHeight: 'none',
      letterSpacing: 'loose',
    },
    display: {
      fontFamily: 'body',
      fontSize: 0,
      fontWeight: 'normal',
      lineHeight: 'none',
      letterSpacing: 'normal',
    },
    comic: {
      fontFamily: 'comic',
      fontSize: 0,
      fontWeight: 'normal',
      lineHeight: 'none',
      letterSpacing: 'loose',
      textTransform: 'uppercase',
    },
  },
  buttons: {
    Button: {
      primary: {
        color: 'white',
        bg: 'primaryLight',
        borderColor: 'primaryLight',
        boxShadow: 0,
        ':hover': {
          boxShadow: 3,
        },
        ':active': {
          bg: 'primary',
        },
        ':disabled': {
          color: 'textDisabled',
          bg: 'blackDisabled',
          borderColor: 'transparent',
          boxShadow: 'none',
        },
      },
      secondary: {
        color: 'text',
        bg: 'secondary',
        borderColor: 'secondary',
        boxShadow: 1,
        ':hover': {
          boxShadow: 3,
        },
        ':active': {
          bg: 'secondaryDark',
          borderColor: 'secondaryDark',
        },
        ':disabled': {
          color: 'textDisabled',
          bg: 'blackDisabled',
          borderColor: 'transparent',
          boxShadow: 'none',
        },
      },
      outlined: {
        color: 'primaryLight',
        bg: 'white',
        borderColor: 'primaryLight',
        ':hover': {
          bg: 'primaryLightHover',
        },
        ':active': {
          bg: 'primaryLightPressed',
        },
        ':disabled': {
          color: 'textDisabled',
          borderColor: 'blackDisabled',
        },
        ':disabled:hover': {
          bg: 'white',
        },
      },
      text: {
        color: 'primaryLight',
        borderColor: 'transparent',
        ':hover': {
          bg: 'primaryLightHover',
        },
        ':active': {
          bg: 'primaryLightPressed',
        },
        ':disabled': {
          color: 'textDisabled',
        },
      },
    },
    ButtonIcon: {
      light: {
        color: 'white',
        ':hover': {
          bg: 'whiteHover',
        },
        ':active': {
          bg: 'whitePressed',
        },
        ':disabled': {
          color: 'whiteDisabled',
          bg: 'transparent',
        },
        ':disabled:hover': {
          color: 'whiteDisabled',
        },
      },
      dark: {
        color: 'black',
        ':hover': {
          bg: 'blackHover',
        },
        ':active': {
          bg: 'blackPressed',
        },
        ':disabled': {
          color: 'textDisabled',
          bg: 'transparent',
        },
        ':disabled:hover': {
          color: 'textDisabled',
        },
      },
    },
  },
  forms: {
    input: {
      variant: 'textStyles.body',
      color: 'text',
      borderColor: 'primaryLight',
      minHeight: 5,
      px: 3,
      ':disabled': {
        color: 'textDisabled',
        borderColor: 'textDisabled',
        backgroundColor: 'neutral.0',
      },
      ':invalid': {
        borderColor: 'primaryLight',
        boxShadow: (theme) => { return `0 0 0 1px ${theme.colors.primaryLight}`; },
      },
      '::placeholder': {
        color: 'textDisabled',
      },
      ':focus': {
        borderColor: 'secondary',
        boxShadow: (theme) => { return `0 0 0 2px ${theme.colors.secondary}`; },
        outline: 'none',
      },
    },
    textarea: {
      variant: 'forms.input',
      // Hardcoded pixel value due to the inability to vertically center
      // text in a textarea whilst requiring an input height of 48px.
      py: '13px',
    },
    select: {
      variant: 'forms.input',
      mr: -1, // Corrects chevron position.
    },
    radio: {
      color: 'primaryLight',
      'input:checked ~ &': {
        color: 'primaryLight',
      },
      'input:disabled ~ &': {
        color: 'textDisabled',
      },
      'input:focus ~ &': {
        outline: 'auto',
      },
    },
    checkbox: {
      variant: 'forms.radio',
    },
    label: {
      color: 'text',
      variant: 'textStyles.body',
    },
    slider: {
      height: '2px',
      bg: 'primaryLight',
      color: 'primaryLight',
      ':focus': {
        color: 'primaryLight',
        outline: 'auto',
      },
      ':disabled': {
        color: 'textDisabled',
      },
      ':invalid': {
        color: 'alerts.error',
      },
    },
  },
  colors: {
    background: '#ffffff',
    white: '#ffffff',
    whiteDisabled: 'rgba(255, 255, 255, 0.2)',
    whiteHover: 'rgba(255, 255, 255, 0.3)',
    whitePressed: 'rgba(255, 255, 255, 0.4)',
    black: '#000000',
    blackDisabled: 'rgba(0, 0, 0, 0.2)',
    blackHover: 'rgba(0, 0, 0, 0.3)',
    blackPressed: 'rgba(0, 0, 0, 0.4)',
    neutral: ['#eceff1', '#cfd8dc', '#b0bec5', '#78909c', '#546e7a', '#37474f'],
    text: 'rgba(0, 0, 0, 0.87)',
    textMediumEmphasis: 'rgba(0, 0, 0, 0.6)',
    textDisabled: 'rgba(0, 0, 0, 0.38)',
    textWhite: 'rgba(255, 255, 255, 1)',
    textWhiteMediumEmphasis: 'rgba(255, 255, 255, 0.6)',
    textWhiteDisabled: 'rgba(255, 255, 255, 0.38)',
    primary: '#493656',
    primaryDark: '#20102d',
    primaryLight: '#756083',
    primaryLightHover: 'rgba(117, 96, 131, 0.38)',
    primaryLightPressed: 'rgba(117, 96, 131, 0.6)',
    secondary: '#ffb300',
    secondaryDark: '#c68400',
    secondaryLight: '#ffe54c',
    secondaryHover: 'rgba(255, 179, 0, 0.38)',
    secondaryPressed: 'rgba(255, 179, 0, 0.6)',
    narrativeBox: '#f7e8b8',
    accents: {
      blue: '#9edaeb',
    },
    alerts: {
      error: '#ff1744',
      information: '#2979ff',
      informationLight: 'rgba(41,121,255,0.2)',
      success: '#00c853',
    },
    charts: {
      data: [
        '#003f5c',
        '#354c78',
        '#68568c',
        '#9c5d92',
        '#ca668c',
        '#ec777c',
        '#ff9368',
        '#ffb759',
        '#c7b24b',
        '#91a74e',
        '#609958',
        '#338863',
        '#007568',
        '#006166',
        '#004d5c',
      ],
      traffic: ['#75b66b', '#fad35c', '#d75656'],
      greyLight: '#cfd8dc',
    },
    versoLeaf: '#66bb6a',
  },
};

/** @jsxImportSource theme-ui */
import { z } from 'zod';
import { ThemeUIProvider } from 'theme-ui';
import { OpenAI } from 'langchain/llms/openai';
import { useEffect } from 'react';
import { PromptTemplate, FewShotPromptTemplate } from 'langchain/prompts';
import { LLMChain } from 'langchain/chains';
import { StructuredOutputParser, OutputFixingParser } from 'langchain/output_parsers';
import { initializeAgentExecutorWithOptions } from 'langchain/agents';
import { SerpAPI } from 'langchain/tools';
import { Calculator } from 'langchain/tools/calculator';
import { fewShotPrompts } from './prompts';
import theme from './theme';

// from langchain.prompts.few_shot import FewShotPromptTemplate
// from langchain.prompts.prompt import PromptTemplate

function App() {
  const simplePrediction = async () => {
    const model = new OpenAI({ temperature: 0, openAIApiKey: '', maxTokens: 2056 });

    // const parser = StructuredOutputParser.fromNamesAndDescriptions({
    //   answer: "final answer to the user's question",
    //   intermediateAnswers: 'intermediate answers to questions',
    // });
    const parser = StructuredOutputParser.fromZodSchema(
      z.object({
        answer: z.string().describe('final answer to the users question'),
        learningIntentions: z
          .array(z.string())
          .describe('improved learning intentions'),
      }),
    );

    const formatInstructions = parser.getFormatInstructions();

    const promptTemplate = new PromptTemplate({
      // template:
      // 'Answer the users question as best as possible.\n{format_instructions}\n{question}',
      template: 'Question: {question}\n{answer}',
      inputVariables: ['question', 'answer'],
    });

    const prompt = await new FewShotPromptTemplate({
      examples: fewShotPrompts,
      examplePrompt: promptTemplate,
      suffix: '{format_instructions}\nQuestion: {input}',
      inputVariables: ['input'],
      partialVariables: { format_instructions: formatInstructions },
    });

    const input = await prompt.format({
      input: 'Please offer improvements to this learning intention: "We are learning about shapes"',
    });

    console.log('input prompt::::', input);

    model.call(input).then((response) => {
      console.log('response::::', response);
      try {
        parser.parse(response).then((res) => {
          console.log('parsedRes:::', res);
        });
      } catch (error) {
        const fixParser = OutputFixingParser.fromLLM(
          model,
          parser,
        );
        fixParser.parse(response).then((output) => {
          console.log('Fixed output: ', output);
        });
      }
    });
  };

  return (
    <ThemeUIProvider theme={theme}>
      <h1
        sx={{
          color: 'text',
          fontFamily: 'heading',
        }}
      >
        Hello world!3
        {' '}
        <button onClick={simplePrediction}>Click for prediction</button>
      </h1>
    </ThemeUIProvider>
  );
}

export default App;

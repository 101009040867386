{
  "name": "@emotion/react",
  "version": "11.11.1",
  "main": "dist/emotion-react.cjs.js",
  "module": "dist/emotion-react.esm.js",
  "browser": {
    "./dist/emotion-react.esm.js": "./dist/emotion-react.browser.esm.js"
  },
  "exports": {
    ".": {
      "module": {
        "worker": "./dist/emotion-react.worker.esm.js",
        "browser": "./dist/emotion-react.browser.esm.js",
        "default": "./dist/emotion-react.esm.js"
      },
      "import": "./dist/emotion-react.cjs.mjs",
      "default": "./dist/emotion-react.cjs.js"
    },
    "./jsx-runtime": {
      "module": {
        "worker": "./jsx-runtime/dist/emotion-react-jsx-runtime.worker.esm.js",
        "browser": "./jsx-runtime/dist/emotion-react-jsx-runtime.browser.esm.js",
        "default": "./jsx-runtime/dist/emotion-react-jsx-runtime.esm.js"
      },
      "import": "./jsx-runtime/dist/emotion-react-jsx-runtime.cjs.mjs",
      "default": "./jsx-runtime/dist/emotion-react-jsx-runtime.cjs.js"
    },
    "./_isolated-hnrs": {
      "module": {
        "worker": "./_isolated-hnrs/dist/emotion-react-_isolated-hnrs.worker.esm.js",
        "browser": "./_isolated-hnrs/dist/emotion-react-_isolated-hnrs.browser.esm.js",
        "default": "./_isolated-hnrs/dist/emotion-react-_isolated-hnrs.esm.js"
      },
      "import": "./_isolated-hnrs/dist/emotion-react-_isolated-hnrs.cjs.mjs",
      "default": "./_isolated-hnrs/dist/emotion-react-_isolated-hnrs.cjs.js"
    },
    "./jsx-dev-runtime": {
      "module": {
        "worker": "./jsx-dev-runtime/dist/emotion-react-jsx-dev-runtime.worker.esm.js",
        "browser": "./jsx-dev-runtime/dist/emotion-react-jsx-dev-runtime.browser.esm.js",
        "default": "./jsx-dev-runtime/dist/emotion-react-jsx-dev-runtime.esm.js"
      },
      "import": "./jsx-dev-runtime/dist/emotion-react-jsx-dev-runtime.cjs.mjs",
      "default": "./jsx-dev-runtime/dist/emotion-react-jsx-dev-runtime.cjs.js"
    },
    "./package.json": "./package.json",
    "./types/css-prop": "./types/css-prop.d.ts",
    "./macro": {
      "types": {
        "import": "./macro.d.mts",
        "default": "./macro.d.ts"
      },
      "default": "./macro.js"
    }
  },
  "types": "types/index.d.ts",
  "files": [
    "src",
    "dist",
    "jsx-runtime",
    "jsx-dev-runtime",
    "_isolated-hnrs",
    "types/*.d.ts",
    "macro.*"
  ],
  "sideEffects": false,
  "author": "Emotion Contributors",
  "license": "MIT",
  "scripts": {
    "test:typescript": "dtslint types"
  },
  "dependencies": {
    "@babel/runtime": "^7.18.3",
    "@emotion/babel-plugin": "^11.11.0",
    "@emotion/cache": "^11.11.0",
    "@emotion/serialize": "^1.1.2",
    "@emotion/use-insertion-effect-with-fallbacks": "^1.0.1",
    "@emotion/utils": "^1.2.1",
    "@emotion/weak-memoize": "^0.3.1",
    "hoist-non-react-statics": "^3.3.1"
  },
  "peerDependencies": {
    "react": ">=16.8.0"
  },
  "peerDependenciesMeta": {
    "@types/react": {
      "optional": true
    }
  },
  "devDependencies": {
    "@definitelytyped/dtslint": "0.0.112",
    "@emotion/css": "11.11.0",
    "@emotion/css-prettifier": "1.1.3",
    "@emotion/server": "11.11.0",
    "@emotion/styled": "11.11.0",
    "html-tag-names": "^1.1.2",
    "react": "16.14.0",
    "svg-tag-names": "^1.1.1",
    "typescript": "^4.5.5"
  },
  "repository": "https://github.com/emotion-js/emotion/tree/main/packages/react",
  "publishConfig": {
    "access": "public"
  },
  "umd:main": "dist/emotion-react.umd.min.js",
  "preconstruct": {
    "entrypoints": [
      "./index.js",
      "./jsx-runtime.js",
      "./jsx-dev-runtime.js",
      "./_isolated-hnrs.js"
    ],
    "umdName": "emotionReact",
    "exports": {
      "envConditions": [
        "browser",
        "worker"
      ],
      "extra": {
        "./types/css-prop": "./types/css-prop.d.ts",
        "./macro": {
          "types": {
            "import": "./macro.d.mts",
            "default": "./macro.d.ts"
          },
          "default": "./macro.js"
        }
      }
    }
  }
}

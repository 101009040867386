import { useState } from 'react';

const useForm = ({ initialState, onSubmit, onChange }) => {
  const [formState, setFormState] = useState(initialState);
  const [validationMessages, setValidationMessages] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  return {
    setValidationMessages,
    validationMessages,
    formState,
    setFormState,
    onSubmit(event) {
      if (event) { event.preventDefault(); }
      onSubmit();
    },
    onChange(event) {
      const target = event.currentTarget;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;

      if (!name) {
        throw Error('Field must have a name attribute!');
      }

      setFormState((state) => { return { ...state, [name]: value }; });

      // eslint-disable-next-line no-unused-vars
      const { [name]: omit, ...otherValidationMessages } = validationMessages;
      setValidationMessages(otherValidationMessages);

      if (typeof onChange === 'function') {
        onChange(event, { ...formState, [name]: value });
      }
    },
    isSubmitting,
    setIsSubmitting,
  };
};

export default useForm;

import { ChatOpenAI } from 'langchain/chat_models/openai';
import { HumanMessage } from 'langchain/schema';
import { useState } from 'react';
import { Button } from 'theme-ui';

function ClaireChat() {
  const message = useState('');

  const simpleCall = async () => {
    const chat = new ChatOpenAI({
      openAIApiKey: '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',
      maxTokens: 25,
      streaming: true,
    });

    const response = await chat.call([new HumanMessage('Tell me a joke.')], {
      callbacks: [
        {
          handleLLMNewToken(token) {
            console.log({ token });
          },
        },
      ],
    });

    console.log(response);
  };

  return (
    <div>
      Claire Chat
      {' '}
      <Button onClick={simpleCall}>Click to get a joke</Button>
    </div>
  );
}

export default ClaireChat;

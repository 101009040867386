/** @jsxImportSource theme-ui */
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import React from 'react';
import { ThemeUIProvider } from 'theme-ui';
import App from './App3';
import Claire from './Claire';
import ClaireChat from './ClaireChat';
import theme from './theme';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Claire />,
  },
  {
    path: '/2',
    element: <ClaireChat />,
  },
]);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ThemeUIProvider theme={theme}>
      <div sx={{
        p: 4, backgroundColor: 'neutral.0', height: '200vh', fontFamily: 'body',
      }}
      >
        <RouterProvider router={router} />
      </div>
    </ThemeUIProvider>
  </React.StrictMode>,
);

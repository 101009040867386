import { getEnvironmentVariable } from "../util/env.js";
import { Tool } from "./base.js";
/**
 * Class for interacting with the Brave Search engine. It extends the Tool
 * class and requires an API key to function. The API key can be passed in
 * during instantiation or set as an environment variable named
 * 'BRAVE_SEARCH_API_KEY'.
 */
export class BraveSearch extends Tool {
    static lc_name() {
        return "BraveSearch";
    }
    constructor(fields = {
        apiKey: getEnvironmentVariable("BRAVE_SEARCH_API_KEY"),
    }) {
        super();
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "brave-search"
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "a search engine. useful for when you need to answer questions about current events. input should be a search query."
        });
        Object.defineProperty(this, "apiKey", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        if (!fields.apiKey) {
            throw new Error(`Brave API key not set. Please pass it in or set it as an environment variable named "BRAVE_SEARCH_API_KEY".`);
        }
        this.apiKey = fields.apiKey;
    }
    /** @ignore */
    async _call(input) {
        const headers = {
            "X-Subscription-Token": this.apiKey,
            Accept: "application/json",
        };
        const searchUrl = new URL(`https://api.search.brave.com/res/v1/web/search?q=${encodeURIComponent(input)}`);
        const response = await fetch(searchUrl, { headers });
        if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
        }
        const parsedResponse = await response.json();
        const webSearchResults = parsedResponse.web?.results;
        const finalResults = Array.isArray(webSearchResults)
            ? webSearchResults.map((item) => ({
                title: item.title,
                link: item.url,
                snippet: item.description,
            }))
            : [];
        return JSON.stringify(finalResults);
    }
}

/** @jsxImportSource theme-ui */
import { jsx } from 'theme-ui';

function Icon({ icon: C, size = 2, ...props }) {
  return (
    <C
      {...props}
      sx={{
        color: 'text',
        // size: 2, https://github.com/styled-system/styled-system/pull/684
        padding: '2px', // Hardcoded padding adjustment to keep SVG sizes inline with Sketch SVGs.
        width: size,
        height: size,
        fill: 'currentColor',
        verticalAlign: 'top', // Removes svg top/bottom whitespace caused by sitting inline on the baseline.
      }}
    />
  );
}

export default Icon;
